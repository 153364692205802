/* CSS */
import "@js/parts/css";

/* JS */
import "@js/parts/lazyloading";
import 'swiper/css';

import Swiper from 'swiper/bundle';
import 'swiper/css/bundle';

// import 'fslightbox';
import "@js/parts/fslightbox";

import ajax from '@imacrayon/alpine-ajax';
Alpine.plugin(ajax)

const swiper = new Swiper('.swiper-slider', {
    loop: false,
    slidesPerView: 1,
    spaceBetween: 47,
    paginationClickable: true,
    navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev"
    },
    breakpoints: {
        767: {
            slidesPerView: 3
        }
    }
});

let sliders1 = document.querySelectorAll('.swiper-slider-1');
if(sliders1) {
    sliders1.forEach(function(item) {
        const swiper1 = new Swiper(item, {
            loop: true,
            slidesPerView: 1,
            spaceBetween: 20,
            paginationClickable: true,
            navigation: {
                nextEl: item.parentElement.querySelector(".swiper-slider-1-next"),
                prevEl: item.parentElement.querySelector(".swiper-slider-1-prev")
            },
            breakpoints: {
                767: {
                    slidesPerView: 1
                }
            }
        });
    });
}

const swiper2 = new Swiper('.swiper-slider-2', {
    loop: true,
    slidesPerView: 1,
    spaceBetween: 20,
    paginationClickable: true,
    navigation: {
        nextEl: ".swiper-button-next"
    },
    breakpoints: {
        767: {
            slidesPerView: 2
        }
    }
});

const swiperNearby = new Swiper('.swiper-slider-3', {
    loop: true,
    slidesPerView: 1,
    spaceBetween: 20,
    paginationClickable: true,
    navigation: {
        nextEl: ".swiper-slider-3-next",
        prevEl: ".swiper-slider-3-prev"
    },
    breakpoints: {
        767: {
            slidesPerView: 2
        }
    }
});

const swiper4 = new Swiper('.swiper-slider-4', {
    loop: false,
    autoHeight: true,
    slidesPerView: 1,
    spaceBetween: 0,
    paginationClickable: true,
    navigation: {
        nextEl: ".swiper-slider-4-next",
        prevEl: ".swiper-slider-4-prev"
    },
    breakpoints: {
        767: {
            slidesPerView: 2
        },
        1024: {
            slidesPerView: 3
        }
    }
});

/**
 * Alpine JS
 * Best to put it last so that all other potential JS is available
 * when components start getting initialized.
 */
import Alpine from 'alpinejs';
window.Alpine = Alpine;
Alpine.start();

let galleryExists = document.querySelector('.fslightbox-gallery')

if(galleryExists) {
    fsLightboxInstances['gallery-top'].props.showThumbsOnMount = true;
    fsLightboxInstances['gallery-bottom'].props.showThumbsOnMount = true;
    fsLightboxInstances['gallery-bottom-videos'].props.showThumbsOnMount = true;
}

function setupReviewSlider()
{
    reviewSwiper = new Swiper('.review-swiper-slider', {
        loop: false,
        slidesPerView: 1,
        spaceBetween: 47,
        paginationClickable: true,
        navigation: {
            nextEl: ".review-swiper-slider-next",
            prevEl: ".review-swiper-slider-prev"
        },
        breakpoints: {
            767: {
                slidesPerView: 2
            }
        }
    });
}

const findReviews = (e) => {
    let content = document.getElementById('ajax-communities');
    var loader = document.getElementById("ajax-loader");
    var e = document.getElementById("sort");
    var queryString = "sort=" + e.value;
    let url = window.location.origin + window.location.pathname + "?" + queryString;

    window.history.pushState({path:url}, '', url);
        const headers = new Headers({
            'X-Requested-With': 'XMLHttpRequest',
            'Content-Type': 'text/html; charset=UTF-8'
        });

    loader.style.display = 'block';
    const myRequest = new Request(url, {
        responseType: 'html',
        method: 'GET',
        headers: headers,
    });

    fetch(myRequest).then(function(response){
        return response.text();
    }).then(function(data){
        loader.style.display = 'none';
        content.innerHTML = data;
        setupReviewSlider();
    })
    .catch(function(err){
        console.log('error', err)
    });
}

let reviewExists = document.querySelector('.review-swiper-slider')
var reviewSwiper;
if(reviewExists) {
    setupReviewSlider();
    document.getElementById("sort").addEventListener("change", findReviews);
}

